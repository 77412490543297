import React from "react";
import { css } from "@emotion/react";
import { Link, navigate } from "gatsby";

import { useCartVisibility } from "../context/cartVisibilityContext";
import { useShopifyRuntime } from "../context/shopifyRunTime";
import LogoImage from "./logoImage";
import { buttonDefault } from "./styleObjects";

const container = css({
  zIndex: 1000,
  position: "fixed",
  left: 0,
  top: 0,
  paddingTop: 3,
  height: "30px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  transition: "background-color 0.2s ease-in-out",
  width: "100%",
  paddingLeft: "1vw",
  paddingRight: "1vw",
});

const linkContainer = css({
  width: "calc(100% / 4)",
  display: "flex",
  alignItems: "center",
});

const portfolioLink = css({
  position: "relative",
  width: 90,
  transform: "translateY(2px)",

  "@media (min-width: 600px)": {
    display: "none",
  },
});

const back = css({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
});

const backIcon = css({
  marginRight: 6,

  "@media (max-width: 800px)": {
    marginRight: 5,
  },
  "@media (max-width: 500px)": {
    marginRight: 3,
  },
  "@media (max-width: 400px)": {
    marginRight: 2,
  },
});

const cartStyle = css({
  // position absolute because 'shop' changes to '<back', which shifts the cart button otherwise
  position: "absolute",
  right: "5.5vw",
  fontSize: "inherit",
  opacity: 0,
  cursor: "pointer",

  "@media (max-width: 1600px)": {
    right: "6vw",
  },
  "@media (max-width: 1100px)": {
    right: "6.5vw",
  },
  "@media (max-width: 1000px)": {
    right: "8vw",
  },
  "@media (max-width: 600px)": {
    right: "9.5vw",
  },
  "@media (max-width: 500px)": {
    right: 46,
  },
  "@media (max-width: 350px)": {
    right: 42,
  },
});

export default function Navigationbar({ pageName, prevPathWasShop }) {
  const cart = useCartVisibility();

  const { checkout } = useShopifyRuntime();

  const atShop = pageName === "shop" || pageName === "product";

  return (
    <div
      css={[
        container,
        cart.isOpen && {
          opacity: 0.4,
          pointerEvents: "none",
        },
      ]}
    >
      <div css={linkContainer}>
        <Link
          css={[buttonDefault, portfolioLink]}
          to={"/portfolio/1"}
          onClick={(e) => {
            e.preventDefault();
            if (pageName !== "portfolio") {
              navigate("/portfolio/1");
            }
          }}
        >
          <LogoImage />
        </Link>
      </div>
      <div css={[linkContainer, { display: "flex", justifyContent: "center" }]}>
        <Link
          css={[buttonDefault, { textAlign: "center" }]}
          to={"/press"}
          onClick={(e) => {
            e.preventDefault();
            if (pageName !== "press") {
              navigate("/press");
            }
          }}
        >
          press
        </Link>
      </div>
      <div css={[linkContainer, { display: "flex", justifyContent: "center" }]}>
        <Link
          css={[buttonDefault, { textAlign: "center" }]}
          to={"/info"}
          onClick={(e) => {
            e.preventDefault();
            if (pageName !== "info") {
              navigate("/info");
            }
          }}
        >
          info
        </Link>
      </div>
      <div
        css={[
          linkContainer,
          {
            justifyContent: "flex-end",
          },
        ]}
      >
        <div
          css={[
            buttonDefault,
            cartStyle,
            !checkout.isInit && { opacity: 0, pointerEvents: "none" },
            atShop && { opacity: 1 },
          ]}
          onClick={cart.isOpen ? cart.close : cart.open}
        >{`cart (${checkout.numItems})`}</div>
        <Link
          css={[buttonDefault, { textAlign: "right" }]}
          to={"/shop"}
          onClick={(e) => {
            e.preventDefault();

            if (window?.history && prevPathWasShop) {
              window.history.back();
            } else {
              navigate("/shop");
            }
          }}
        >
          {pageName === "product" ? (
            <div css={back}>
              <p css={backIcon}>&lt;</p>
              <p>back</p>
            </div>
          ) : (
            <p>shop</p>
          )}
        </Link>
      </div>
    </div>
  );
}
