import React, { useState } from "react";

const Context = React.createContext(null);

function CartVisibilityProvider({ children }) {
  const [isOpen, setIsOpen] = useState(false);

  const value = {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useCartVisibility() {
  const context = React.useContext(Context);
  if (!context)
    throw new Error(
      "useCartVisibility must be used within the NavigationProvider"
    );

  return context;
}

export { CartVisibilityProvider, useCartVisibility };
