import React from "react";
import { css } from "@emotion/react";

import image from "../images/logo.png";

const LogoImage = () => (
  <img
    src={image}
    loading="eager"
    sizes="500px"
    alt="logo"
    css={[
      css({
        position: "absolute",
        width: "100%",
        left: 0,
        top: 0,
        transform: "translateY(-35%)",
      }),
    ]}
  />
);

export default LogoImage;
