import React from "react";
import { css, keyframes } from "@emotion/react";
import { Link, navigate } from "gatsby";

import LogoImage from "./logoImage";

import { buttonDefault } from "./styleObjects";

const moveLogo = keyframes`
  from {
    transform: rotate(-90deg) translateX(-100%);
  }
  to {
    transform: rotate(-90deg) translateX(calc(100vh + 100%)) ;
  }
`;

const container = css({
  zIndex: 2000,
  position: "fixed",
  bottom: 0,
  left: 70, // manual
  color: "black",
  display: "inline-block",
  transformOrigin: "bottom left",
  animation: `${moveLogo} 8s linear infinite`,
  width: 220,
  opacity: 1,

  ":hover": { animationPlayState: "paused" },

  "@media (min-width: 500px) and (min-height: 750px)": {
    animationDuration: "10.5s",
  },
  "@media (min-width: 500px) and (min-height: 1400px)": {
    animationDuration: "12s",
  },
  "@media (max-width: 599px)": {
    display: "none",
  },

  "@media (max-width: 1800px)": {
    width: 200,
  },
  "@media (max-width: 1600px)": {
    width: 185,
    left: 60,
  },
  "@media (max-width: 1300px)": {
    width: 170,
    left: 55,
  },
  "@media (max-width: 1200px)": {
    width: 160,
    left: 50,
  },
  "@media (max-width: 1100px)": {
    width: 150,
  },
  "@media (max-width: 1000px)": {
    width: 140,
    left: 45,
  },
});

export default function LogoMoving({ pageName }) {
  return (
    <Link
      css={[
        buttonDefault,
        container,
        pageName === "info" && {
          animationPlayState: "paused",
        },
      ]}
      to="/portfolio/1"
      onClick={(e) => {
        e.preventDefault();
        if (pageName !== "portfolio") {
          setTimeout(() => {
            navigate("/portfolio/1");
          }, 300);
        }
      }}
    >
      <LogoImage />
    </Link>
  );
}
