import * as React from "react";
import { useMutation, useQuery } from "react-query";
import ShopifyClient from "shopify-buy";

const Context = React.createContext(null);

const ShopifyRuntimeProvider = ({ children }) => {
  const [checkout, setCheckout] = React.useState(null);

  const clientRef = React.useRef(
    ShopifyClient.buildClient({
      storefrontAccessToken: "aec2e7a9ff50e738535be4a359037f1f",
      domain: "orders.amyrodriguez.art",
    })
  );
  const client = clientRef.current;

  const handleCheckoutInit = async () => {
    if (checkout) {
      return;
    }
    const myCheckout = await client.checkout.create();
    setCheckout(myCheckout);
  };

  React.useEffect(() => {
    if (!client || checkout) {
      return;
    }
    handleCheckoutInit();
  }, [client]);

  const productsQuery = useQuery(
    "products",
    async () => await client.product.fetchAll(250)
  );

  const getOneProduct = (id) =>
    productsQuery.data?.find((product) => product.id === id);

  const addItemMutation = useMutation(async (variantId) => {
    await handleCheckoutInit();

    const newItem = [{ variantId, quantity: 1 }];

    const updatedCheckout = await client.checkout.addLineItems(
      checkout.id,
      newItem
    );

    setCheckout(updatedCheckout);
  });

  const removeItemMutation = useMutation(async (variantId) => {
    const updatedCheckout = await client.checkout.removeLineItems(checkout.id, [
      variantId,
    ]);

    setCheckout(updatedCheckout);
  });

  const checkCheckoutContainsItem = (variantId) => {
    return Boolean(
      checkout?.lineItems.find((lineItem) => lineItem.variant.id === variantId)
    );
  };

  const value = {
    products: {
      query: productsQuery,
      helpers: {
        getOne: getOneProduct,
      },
    },
    checkout: {
      isInit: Boolean(checkout),
      numItems: checkout?.lineItems?.length || 0,
      items: checkout?.lineItems.map((item) => ({
        checkoutId: item.id,
        title: item.title,
        availableForSale: item.variant.available,
        shopifyId: item.variant.id,
        price: item.variant.price.amount,
        image: item.variant.image.src,
      })),
      goToShopifyCheckout: () => window.open(checkout.webUrl, "_self"),
      mutations: {
        addItem: addItemMutation,
        removeItem: removeItemMutation,
      },
      helpers: {
        checkCheckoutContainsItem,
      },
    },
  };

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

const useShopifyRuntime = () => {
  const context = React.useContext(Context);

  if (!context) {
    throw new Error("useShopifyRunTime must be used within its provider!");
  }

  return context;
};

export { ShopifyRuntimeProvider, useShopifyRuntime };
