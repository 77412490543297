import * as React from "react";
import { Global, css } from "@emotion/react";
import { Helmet } from "react-helmet";

import "../styles/index.css";

import fontReg from "../../static/fonts/EB_Garamond/EBGaramond-Regular.woff2";

function GlobalLayout({ children }) {
  return (
    <div>
      <Helmet>
        <title>amy rodriguez</title>
        <meta
          name="description"
          content="Jewellery. Portfolio and shop. Worldwide shipping. Free domestic shipping."
        />
        <link
          rel="preload"
          as="font"
          href={fontReg}
          type="font/woff2"
          crossOrigin="anonymous"
        ></link>
        <link rel="manifest" href="/manifest.webmanifest"></link>
        <meta
          name="facebook-domain-verification"
          content="gn5qj4m40zjay3163y269n4qsofz8i"
        />
      </Helmet>
      <Global
        styles={css`
          * {
            margin: 0;
            padding: 0;
            user-select: none;
            box-sizing: border-box;
            outline: none;
            -webkit-tap-highlight-color: transparent;
          }
          body {
            font-family: EB Garamond, serif;
            font-size: 18px;

            ::-webkit-scrollbar {
              width: 9px;
            }

            ::-webkit-scrollbar-track {
              background: white;
            }
            ::-webkit-scrollbar-track:hover {
              background: #d1d5db;
            }

            ::-webkit-scrollbar-thumb {
              background: white;
            }

            ::-webkit-scrollbar-thumb:hover {
              background: white;
            }

            @media (max-width: 1500px) {
              font-size: 17px;
            }
            @media (max-width: 1400px) {
              font-size: 16px;
            }
            @media (max-width: 1100px) {
              font-size: 15px;
            }
            @media (max-width: 1000px) {
              font-size: 14px;
            }
            @media (max-width: 800px) {
              font-size: 13px;
            }
          }

          a,
          a:link,
          a:visited {
            color: black;
            padding: 4px;
            text-decoration: none;
          }
          input,
          input:focus {
            border: none;
          }
          button {
            border: none;
            outline: none;
          }
        `}
      />
      {children}
    </div>
  );
}

export default GlobalLayout;
