import { css, keyframes } from "@emotion/react"

const containerScreenWidthAndHeight = css({
  position: "relative",
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
})

const buttonDefault = css({
  padding: 4,
  cursor: "pointer",
  boxSizing: "content-box",
  transition: "opacity .1s ease-in-out",

  "&:hover": { opacity: 0.8 },
  "&:active": { opacity: 1 },
})

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1
  }
`

const animationFadeIn = css({
  animation: `${FadeIn} 0.5s ease-in`,
})

const transitionFadeIn = css({
  opacity: 1,
  pointerEvents: "auto",
  transition: "opacity 0.1s ease-in",
})

const transitionFadeOut = css({
  opacity: 0,
  pointerEvents: "none",
  transition: "opacity 0.1s ease-out",
})

const flexCenterContent = css({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
})

export {
  containerScreenWidthAndHeight,
  buttonDefault,
  animationFadeIn,
  transitionFadeIn,
  transitionFadeOut,
  flexCenterContent,
}
