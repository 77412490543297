import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";

import { ShopifyRuntimeProvider } from "./src/context/shopifyRunTime";
import { CartVisibilityProvider } from "./src/context/cartVisibilityContext";

const queryClient = new QueryClient();

const RootWrapper = ({ children }) => (
  <>
    <QueryClientProvider client={queryClient}>
      <ShopifyRuntimeProvider>
        <CartVisibilityProvider>{children}</CartVisibilityProvider>
      </ShopifyRuntimeProvider>
    </QueryClientProvider>
  </>
);

// eslint-disable-next-line react/display-name
export default ({ element }) => <RootWrapper>{element}</RootWrapper>;
