import React from "react";

import GlobalLayout from "./src/components/globalLayout";
import NavigationBar from "./src/components/navigationBar";
import LogoAnimated from "./src/components/logoAnimated";
import Cart from "./src/components/cart";

const getPageNameFromProps = (pageProps) => {
  if (pageProps.path.includes("/portfolio/")) {
    return "portfolio";
  }
  if (pageProps.path.includes("/press")) {
    return "press";
  }
  if (pageProps.path.includes("/info")) {
    return "info";
  }
  if (pageProps.path.includes("/shop")) {
    return "shop";
  }
  if (pageProps.pageContext.pageType === "product") {
    return "product";
  }

  return "unknown";
};

const wrapPageElement = ({ element, props }) => {
  const pageName = getPageNameFromProps(props);

  return (
    <GlobalLayout>
      {element}
      <NavigationBar
        pageName={pageName}
        prevPathWasShop={props.location?.state?.prevPath === "shop"}
      />
      <LogoAnimated pageName={pageName} />
      <Cart pageName={pageName} />
    </GlobalLayout>
  );
};

export default wrapPageElement;
